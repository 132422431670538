<template>
  <div>
    <div class="">
      <div class="w-full h-28 md:h-44 md:bg-primary-black"></div>
      <div class="flex justify-center items-center -mt-14">
        <img
          :src="producerInfo.producerImage"
          alt=""
          class="rounded-full w-28 h-28 border-4 border-surface-white"
        />
      </div>
      <div
        class="flex flex-col justify-center items-center text-center max-w-md mx-auto mt-8 px-4 md:px-0"
      >
        <p class="text-grey-700 text-xs uppercase">
          {{ producerInfo.artworkGenreName }}
        </p>
        <p class="text-grey-900 text-2xl md:text-5xl font-bold mt-2">
          {{ producerInfo.producerName }}
        </p>
        <p class="text-grey-900 text-sm mt-4 md:mt-5">
          {{ producerInfo.producerDec }}
        </p>
      </div>
    </div>
    <main class="mt-20 container">
      <div class="">
        <div class="md:w-2/4 mx-auto">
          <div class="flex items-center justify-center space-x-8 md:space-x-14 mt-12">
            <button
              @click="getProductsByproducer()"
              class="pb-5 text-sm focus:outline-none"
              :class="showProducts ? 'border-b-2 font-medium' : 'text-grey-500'"
            >
              Shop
            </button>
            <button
              @click="GetArtworkByproducer()"
              class="pb-5 text-sm focus:outline-none"
              :class="showArtwors ? 'border-b-2 font-medium' : 'text-grey-500'"
            >
              Artwork Gallery
            </button>
          </div>
        </div>
        <hr class="border-grey-100 w-full" />

        <!--  lg:-mt-44 -->
        <div class="flex justify-between items-end mt-7 md:-mt-11">
          <div class=""></div>

          <div class="">
            <div class="hidden lg:flex justify-end items-center">
              <p class="text-grey-500 text-sm mr-2">Sort by</p>
              <div class="relative">
                <button
                  @click="sortByList = !sortByList"
                  class="flex items-center focus:outline-none"
                >
                  <span
                    class="text-grey-800 border-b border-primary-black mr-2 text-xs font-medium"
                    >{{ sortByText }}</span
                  >
                  <span>
                    <svg
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 1L4 4L1 1" stroke="black" />
                    </svg>
                  </span>
                </button>

                <div
                  v-if="sortByList"
                  class="absolute z-40 shadow bg-surface-white py-2 px-4 top-6 space-y-4"
                >
                  <button
                    @click="sortByFun('Latest')"
                    class="text-grey-800 text-sm font-medium"
                  >
                    Latest
                  </button>

                  <button
                    @click="sortByFun('Oldest')"
                    class="text-grey-800 text-sm font-medium"
                  >
                    Oldest
                  </button>
                </div>
              </div>
            </div>

            <div class="lg:hidden">
              <button class="flex items-center focus:outline-none">
                <span>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.5 0C3.15311 0 3.70873 0.417404 3.91465 1H14V2H3.91465C3.70873 2.5826 3.15311 3 2.5 3C1.84689 3 1.29127 2.5826 1.08535 2H0V1H1.08535C1.29127 0.417404 1.84689 0 2.5 0ZM11.5 5C12.1531 5 12.7087 5.4174 12.9146 6H14V7H12.9146C12.7087 7.5826 12.1531 8 11.5 8C10.8469 8 10.2913 7.5826 10.0854 7H0V6H10.0854C10.2913 5.4174 10.8469 5 11.5 5ZM7.5 10C6.84689 10 6.29127 10.4174 6.08535 11H0V12H6.08535C6.29127 12.5826 6.84689 13 7.5 13C8.15311 13 8.70873 12.5826 8.91465 12H14V11H8.91465C8.70873 10.4174 8.15311 10 7.5 10ZM2.02819 1.66675L1.96925 1.5L2.02819 1.33325C2.09731 1.1377 2.28386 1 2.5 1C2.71614 1 2.90269 1.1377 2.97181 1.33325L3.03075 1.5L2.97181 1.66675C2.90269 1.8623 2.71614 2 2.5 2C2.28386 2 2.09731 1.8623 2.02819 1.66675ZM11.0282 6.66675L10.9693 6.5L11.0282 6.33325C11.0973 6.1377 11.2839 6 11.5 6C11.7161 6 11.9027 6.1377 11.9718 6.33325L12.0307 6.5L11.9718 6.66675C11.9027 6.8623 11.7161 7 11.5 7C11.2839 7 11.0973 6.8623 11.0282 6.66675ZM6.96925 11.5L7.02819 11.3332C7.09731 11.1377 7.28386 11 7.5 11C7.71614 11 7.90269 11.1377 7.97181 11.3332L8.03075 11.5L7.97181 11.6668C7.90269 11.8623 7.71614 12 7.5 12C7.28386 12 7.09731 11.8623 7.02819 11.6668L6.96925 11.5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span class="uppercase text-xs sm:text-sm text-grey-900 ml-3"
                  >Filter</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between items-start mt-24">
        <aside class="hidden lg:block lg:w-1/4 pr-10">
          <div class="border border-grey-100 pl-7 pr-4 pt-9 pb-14">
            <div id="category" class="">
              <button
                @click="categoriesListShow = !categoriesListShow"
                class="flex justify-between items-center w-full focus:outline-none"
              >
                <span class="text-xs text-grey-900 uppercase">Categories</span>
                <span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11 1L6 6L1 1" stroke="#B1B1B1" stroke-width="2" />
                  </svg>
                </span>
              </button>
              <!-- Category -->
              <div
                v-show="categoriesListShow"
                @click="selectFilterCategory(subcatagory.categoryId, $event)"
                v-for="(subcatagory, index) in subcatagories"
                :key="index"
                class="flex items-start mt-4"
              >
                <div class="h-5 flex items-center">
                  <input
                    id="T_Shirts"
                    type="checkbox"
                    class="form-checkbox text-primary-black rounded border-2 border-primary-black focus:outline-none focus:border-primary-black"
                  />
                </div>
                <div class="ml-2 text-sm">
                  <label for="T_Shirts" class="text-grey-500 text-sm">{{
                    subcatagory.categoryName
                  }}</label>
                </div>
              </div>
              <hr class="w-full border-grey-100 mt-6" />
            </div>

            <!-- //color -->
            <div id="Colors" class="mt-6">
              <button
                @click="colorListShow = !colorListShow"
                class="flex justify-between items-center w-full focus:outline-none"
              >
                <span class="text-xs text-grey-900 uppercase">COLORS</span>
                <span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11 1L6 6L1 1" stroke="#B1B1B1" stroke-width="2" />
                  </svg>
                </span>
              </button>

              <!-- <div class="text-xs text-grey-900 uppercase">COLORS AVAILABLE</div> -->

              <div v-show="colorListShow" class="grid grid-cols-5 gap-5 mt-6">
                <button
                  @click="selectColor(color.colorId)"
                  v-for="(color, index) in colors"
                  :key="index"
                  v-bind:style="{ backgroundColor: color.hexadecimal }"
                  class="w-8 h-8 rounded-full border bg-red-100 focus:outline-none"
                ></button>
              </div>

              <hr class="w-full border-grey-100 mt-6" />
            </div>
            <!-- //size -->
            <div id="Size" class="mt-6">
              <button
                @click="sizesListShow = !sizesListShow"
                class="flex justify-between items-center w-full focus:outline-none"
              >
                <span class="text-xs text-grey-900 uppercase">sizes</span>
                <span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11 1L6 6L1 1" stroke="#B1B1B1" stroke-width="2" />
                  </svg>
                </span>
              </button>
              <!-- <div class="text-xs text-grey-900 uppercase">sizes available</div> -->
              <div v-show="sizesListShow" class="flex flex-wrap mt-6">
                <button
                  @click="selectSize(size.sizeId)"
                  v-for="(size, index) in sizes"
                  :key="index"
                  class="border border-grey-700 focus:outline-none uppercase px-4 py-2 text-sm m-1"
                >
                  {{ size.sizeName }}
                </button>
              </div>
            </div>
          </div>
        </aside>

        <section v-if="showProducts" class="lg:w-3/4">
          <div v-if="productsLoading" class="animate-pulse mt-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-16">
            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>

            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>

            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>
          </div>
          <div v-else class="w-full">
            <div v-if="productProducer != 0" class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-10">
                <router-link
                  :to="{
                    name: 'product',
                    params: { id: prudactsArtwork.productId },
                  }"
                  class="group"
                  :key="index"
                  v-for="(prudactsArtwork, index) in productProducer"
                >
                  <div
                    class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-80 hover:border-grey-300 transform duration-700"
                  >
                    <img
                      :src="prudactsArtwork.image"
                      alt=""
                      class="w-full h-full"
                    />
                  </div>

                  <span
                    class="mt-8 text-sm text-grey-500 font-normal block text-center"
                  >
                    {{ prudactsArtwork.productName }}
                  </span>
                </router-link>
            </div>
            <div v-else class="flex justify-center items-center h-52 text-grey-200">
              The designer hasn't added product yet.
            </div>
          </div>
        </section>

        <section v-if="showArtwors" id="Latest_work_p" class="lg:w-3/4">
          <div v-if="artworsLoading" class="animate-pulse mt-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-16">
            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>

            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>

            <div class="group">
              <div class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96">
                <div class="w-full h-full bg-surface-grey"></div>
              </div>
              <div class="mt-8  bg-surface-grey w-48 h-6 mx-auto"></div>
              <span class="mt-2 block text-center">
                <span class="bg-surface-grey w-48 h-6 mx-auto"></span>
              </span>
            </div>
          </div>
          <div v-else class="w-full">
            <div v-if="artworksProducer != 0" class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-3 lg:gap-x-4 gap-y-10">
              <router-link
              :to="{
                name: 'product',
                params: { id: artworkProducer.artworkId },
              }"
              class="group"
              :key="index"
              v-for="(artworkProducer, index) in artworksProducer"
            >
              <div
                class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-80 hover:border-grey-300 transform duration-700"
              >
                <img
                  :src="artworkProducer.artWorkImage"
                  alt=""
                  class="w-full h-full"
                />
              </div>

              <span
                class="mt-8 text-sm text-grey-500 font-normal block text-center"
              >
                {{ artworkProducer.artworkName }}
              </span>
              </router-link>
            </div>
            <div v-else class="flex justify-center items-center h-52 text-grey-200">
              The designer hasn't added artwork yet.
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  components: {},
  created() {
    window.scroll(0, 0);
    this.getProductsByproducer();
    this.GetProducerById();
    this.getFilter();
  },
  data() {
    return {

      showProducts: true,
      showArtwors: false,

      productsLoading: true,
      artworsLoading: false,


      categoriesListShow: false,
      producerListShow: false,
      genderListShow: false,
      colorListShow: false,
      sizesListShow: false,
      producerId: this.$route.params.id,
      artworkId: 17,
      artworkGenreId: 9,
      artworks: [],
      productProducer: [],
      producerInfo: {
        artworkGenreName: "",
        producerImage: " ",
        producerName: "",
        producerDec: "",
      },
      catagories: [],
      products: [],
      subcatagories: [],
      sizes: [],
      colors: [],
      artworksProducer: [],
       sortByText: "Latest",
      sortByList: false,
      filter: {
        pageNo: 1,
        pageSize: 10,
        producerId: Number(this.$route.params.id),
        FilterCategories: [],
        Filtercolors:null ,
        FilterSizes: null,
        // gender: "",
        sortBy: "Latest",
      },
    };
  },
  methods: {
     getProductsByproducer() {
        this.productsLoading = true;

        this.showProducts = true;
        this.showArtwors = false;

      this.$http.productsService
        .GetProducts(this.filter)
        .then((res) => {
          setTimeout(() => {
            this.data = res.data;
            this.artworksProducer=[]

            this.productProducer = res.data.result.productList;

            this.productsLoading = false;
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    GetArtworkByproducer() {
      this.artworsLoading = true;
      
      this.showProducts = false;
      this.showArtwors = true;

      this.$http.producersService
        .GetArtworkByproducer(this.producerId)
        .then((res) => {
          setTimeout(() => {
            this.data = res.data;
            this.productProducer = [];
            this.artworksProducer = res.data.result.artworksProducer;

            this.artworsLoading = false;
          }, 500);

         
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectSize(FilterSizes) {
      this.filter.FilterSizes = FilterSizes;
      this.getProductsByproducer();
    },
    selectColor(Color) {
      this.filter.Filtercolors = Color;
      this.getProductsByproducer();
    },
    selectFilterCategory(categoryId, event) {
      if (event.target.checked) {
        this.filter.FilterCategories.push(categoryId);
      } else {
        var index = this.filter.FilterCategories.indexOf(categoryId);
        if (index >= 0) {
          this.filter.FilterCategories.splice(index, 1);
        }
      }
       this.filter.FilterCategories = JSON.parse(JSON.stringify(this.filter.FilterCategories));
     
     this.getProductsByproducer();
    },
    GetProducerById() {
      this.$http.producersService
        .GetProducerById(this.producerId)
        .then((res) => {
          this.data = res.data;
          this.producerInfo.producerImage = res.data.result[0].producerImage;
          this.producerInfo.producerName = res.data.result[0].producerName;
          this.producerInfo.producerDec = res.data.result[0].producerDec;
          res.data.result.forEach((e) => {
            this.producerInfo.artworkGenreName =
              this.producerInfo.artworkGenreName + " / " + e.artworkGenreName;
          });
          this.producerInfo.artworkGenreName = this.producerInfo.artworkGenreName.slice(
            3
          );

          console.log(this.producerInfo.artworkGenreName);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // GetArtworkById() {
    //   this.$http.producersService
    //     .GetArtworkById(this.artworkId)
    //     .then((res) => {
    //       this.data = res.data;
    //       this.artworks = res.data.result;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    sortByFun(sort) {
      this.filter.sortBy = sort;
      this.sortByText = sort;
      this.getProductsByproducer();
    },
   

    getFilter() {
      this.$http.productsService
        .GetFilter()
        .then((res) => {
          this.data = res.data;
          this.subcatagories = res.data.result.subcategoriesFilter;
          this.sizes = res.data.result.sizesFilter;
          this.colors = res.data.result.colorsFilter;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
  },
};
</script>
